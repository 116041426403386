<template>
  <textarea v-bind="inputAttrs" v-model="value">{{ value }}</textarea>
  <span
    v-if="hasCharacterCounter"
    class="character-counter"
    style="display: block">
    {{
      translate("core.form.characterLimit", {
        current: currentCharacters,
        max: maxCharacters,
      })
    }}
  </span>
</template>

<script>
import textAreaInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/textAreaInput.js";

export default {
  mixins: [textAreaInputMixin],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss"></style>
